define('fotobox/components/m-image', ['exports', 'ember-m-image/components/m-image'], function (exports, _mImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mImage.default;
    }
  });
});