define('fotobox/config/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			didTransition() {
				this.controller.loadcontroller();
			},
			willTransition() {
				this.controller.willGo();
			}
		}
	});
});