define('fotobox/utils/m-image-ajax-loader', ['exports', 'ember-m-image/utils/m-image-ajax-loader'], function (exports, _mImageAjaxLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mImageAjaxLoader.default;
    }
  });
});