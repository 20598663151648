define('fotobox/components/dia-show/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		start: 0,
		didInsertElement() {
			this._super(...arguments);
			var _self = this;
			this.set("start", 0);
		},
		actions: {
			select(active) {
				this.set('active', active);
			},
			next(skip) {
				if (parseInt(this.get("start")) + skip <= this.get("data.length") - 10) {
					this.set('start', parseInt(this.get("start")) + skip);
				}
			},
			prev(skip) {
				if (parseInt(this.get("start")) - skip >= 0) {
					this.set('start', parseInt(this.get("start")) - skip);
				}
			}
		}
	});
});