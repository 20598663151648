define('fotobox/components/slide-show/component', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { socketip } = _emberGetConfig.default;

  exports.default = Ember.Component.extend({
    socketip: socketip,
    doit: true,
    img1: 'http://' + socketip + ':3000/img1',
    img2: 'http://' + socketip + ':3000/img2',
    img3: 'http://' + socketip + ':3000/img3',
    img4: 'http://' + socketip + ':3000/img4',
    didInsertElement() {

      this._super(...arguments);
      this.set('doit', true);
      this.reloadImgage();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.set('doit', false);
    },
    reloadImgage() {
      if (this.get("doit")) {
        var _self = this;

        this.set('img1', 'http://' + socketip + ':3000/img1?' + Math.floor(Math.random() * 100000000000));
        this.set('img2', 'http://' + socketip + ':3000/img2?' + Math.floor(Math.random() * 100000000000));
        this.set('img3', 'http://' + socketip + ':3000/img3?' + Math.floor(Math.random() * 100000000000));
        this.set('img4', 'http://' + socketip + ':3000/img4?' + Math.floor(Math.random() * 100000000000));

        Ember.run.later(function () {
          _self.reloadImgage();
        }, 20000);
      }
    },
    actions: {
      onError() {}
    }

  });
});