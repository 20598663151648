define("fotobox/components/key-dect/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    myaction: false,
    didRender: function () {
      this.$().attr({ tabindex: 1 });
      this.$().focus();
    },
    keyDown(event) {
      this.get("myaction")(String.fromCharCode(event.keyCode));
    }
  });
});