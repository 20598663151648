define("fotobox/components/show-pics/component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		current: 0,

		didReceiveAttrs() {
			this._super(...arguments);
			this.set("current", 0);
		},
		actions: {
			setCurrent(index) {
				this.set("current", index);
			}
		}
	});
});