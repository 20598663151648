define('fotobox/components/get-data/component', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { socketip, pathtopics, pathtovideos, pathtoaudios } = _emberGetConfig.default;
  exports.default = Ember.Component.extend({
    greenaction: false,
    orangeaction: false,
    socketIOService: Ember.inject.service('socket-io'),
    pathtopics: '',
    namespace: '',

    didInsertElement() {
      this._super(...arguments);
      var _self = this;
      this.set("pathtopics", pathtopics);
      const socket = this.socketIOService.socketFor(`${socketip}`);

      socket.emit("get-data", "_de");

      socket.on('connect', this.onConnect, this);
      socket.on('message', this.onMessage, this);

      socket.on('databasedata', this.databasedata, this);
    },
    databasedata(value) {
      if (value) {
        console.log("databasedata", value);
        var newArray = Ember.ArrayProxy.create({ content: Ember.A([]) });
        for (let row of value) {
          console.log("row", row);
          var images = [];
          var video = "";
          if (row && row["Detail-Daten"] && row["Type"] == "Bilder") {
            let parts = row["Detail-Daten"].split(",");
            for (let part of parts) {
              images.push({
                img: pathtopics + part
              });
            }
          }
          if (row && row["Detail-Daten"] && row["Type"] == "Video") {
            video = pathtovideos + row["Detail-Daten"];
          }
          newArray.addObject({
            name: row.Name,
            image: pathtopics + row.Vorschaubild,
            type: row.Type,
            images: images,
            video: video,
            ton: pathtoaudios + row.Ton,
            text: {
              title: row["Text-Titel"],
              first: row["Text-Bereich 1"],
              secound: row["Text-Bereich 2"]
            }

          });
          this.set("data", newArray);
          this.set("active", newArray.get("firstObject"));
        }
      }
    },

    onConnect() {
      const socket = this.socketIOService.socketFor(`${socketip}`);

      /*
        There are 2 ways to send messages to the server: send and emit
      */
      socket.send('Hello World');
    },

    onMessage(data) {
      // This is executed within the ember run loop
    },

    willDestroyElement() {
      this._super(...arguments);

      const socket = this.socketIOService.socketFor(`${socketip}`);
      socket.off('connect', this.onConnect);
      socket.off('message', this.onMessage);
      socket.off('databasedata', this.databasedata);
    },
    actions: {
      lang(lang) {
        const socket = this.socketIOService.socketFor(`${socketip}`);

        socket.emit("get-data", "_" + lang);
      }
    }

  });
});