define('fotobox/components/press-green-button-start/component', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { socketip } = _emberGetConfig.default;
  exports.default = Ember.Component.extend({
    greenaction: false,
    orangeaction: false,
    socketIOService: Ember.inject.service('socket-io'),

    namespace: '',

    didInsertElement() {
      this._super(...arguments);
      var _self = this;
      const socket = this.socketIOService.socketFor(`${socketip}`);

      socket.on('connect', this.onConnect, this);
      socket.on('message', this.onMessage, this);

      socket.on('buttonESC', this.esc, this);
      socket.on('buttonDown', this.down, this);
      socket.on('buttonUp', this.up, this);
      socket.on('buttonStart', this.start, this);
    },
    esc(value) {
      if (value) {
        console.log("buttonESC", value);
        this.get('escaction')();
      }
    },

    down(value) {
      if (value) {
        console.log("buttonDown", value);
        this.get('downaction')();
      }
    },
    up(value) {
      if (value) {
        console.log("buttonUp", value);
        this.get('upaction')();
      }
    },
    start(value) {
      if (value) {
        console.log("buttonStart", value);
        this.get('startaction')();
      }
    },

    onConnect() {
      const socket = this.socketIOService.socketFor(`${socketip}`);

      /*
        There are 2 ways to send messages to the server: send and emit
      */
      socket.send('Hello World');
    },

    onMessage(data) {
      // This is executed within the ember run loop
    },

    willDestroyElement() {
      this._super(...arguments);

      const socket = this.socketIOService.socketFor(`${socketip}`);
      socket.off('connect', this.onConnect);
      socket.off('message', this.onMessage);
      socket.off('buttonESC', this.esc);
      socket.off('buttonDown', this.down);
      socket.off('buttonUp', this.up);
      socket.off('buttonStart', this.start);
    }

  });
});