define('fotobox/components/transponder-read/component', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { socketip, pathtopics, pathtovideos } = _emberGetConfig.default;
  exports.default = Ember.Component.extend({
    greenaction: false,
    orangeaction: false,
    socketIOService: Ember.inject.service('socket-io'),

    namespace: '',

    didInsertElement() {
      this._super(...arguments);
      var _self = this;
      const socket = this.socketIOService.socketFor(`http://${socketip}:3001/${this.namespace}`);

      socket.on('connect', this.onConnect, this);
      socket.on('message', this.onMessage, this);

      socket.on('transponder', this.transponder, this);
    },
    transponder(value) {
      if (value) {
        this.get("myaction")(value);
      }
    },

    onConnect() {
      const socket = this.socketIOService.socketFor(`http://${socketip}:3001/${this.namespace}`);

      /*
        There are 2 ways to send messages to the server: send and emit
      */
      socket.send('Hello World');
    },

    onMessage(data) {
      // This is executed within the ember run loop
    },

    willDestroyElement() {
      this._super(...arguments);

      const socket = this.socketIOService.socketFor(`http://${socketip}:3001/${this.namespace}`);
      socket.off('connect', this.onConnect);
      socket.off('message', this.onMessage);
      socket.off('transponder', this.transponder);
    }

  });
});