define('fotobox/config/controller', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	const { socketip, socketconfigip, pathtopics, pathtovideos } = _emberGetConfig.default;

	exports.default = Ember.Controller.extend({
		socketIOService: Ember.inject.service('socket-io'),
		num: "",
		pin: "",
		pinOK: "",
		lastmsg: "",
		namespace: '',
		onConnect() {
			const socket = this.socketIOService.socketFor(`${socketconfigip}`);
		},

		onMessage(data) {
			// This is executed within the ember run loop
		},
		willGo() {

			const socket = this.socketIOService.socketFor(`${socketconfigip}`);
			socket.off('connect', this.onConnect);
			socket.off('message', this.onMessage);
		},
		loadcontroller() {
			this.set("pinOK", false);
			this.set("num", "");
			this.set("pin", "");
			const socket = this.socketIOService.socketFor(`${socketconfigip}`);
			socket.on('connect', this.onConnect, this);
			socket.on('message', this.onMessage, this);
			this.set("socket", socket);
		},
		actions: {
			addnum(num) {
				if (typeof num == "STRING" && num.charCodeAt() == 13) {
					this.send('oknum');
				} else {
					this.set('num', this.get("num") + num);
				}
			},
			clearnum() {
				this.set("num", "");
			},
			oknum: async function () {
				this.set("lastmsg", "");
				var _self = this;
				const socket = this.socketIOService.socketFor(`${socketconfigip}`);
				socket.emit('load-config', this.get("num"));
				socket.on('messageSuccess', function (data) {

					if (_self.get("lastmsg") != data) {
						alert(data);
					}
					_self.set("lastmsg", data);
				});
			},
			addpin(num) {
				console.log("num", num.charCodeAt());
				if (typeof num == "STRING" && num.charCodeAt() == 13) {
					this.send('okpin');
				} else {
					this.set('pin', this.get("pin") + num);
				}
			},
			clearpin() {
				this.set("pin", "");
			},
			okpin() {
				if (this.get("pin") && this.get("pin") == "0815") {
					this.set("pinOK", true);
				}
			},
			close() {
				const socket = this.socketIOService.socketFor(`${socketconfigip}`);
				socket.emit('close-software', this.get("num"));
			}
		}
	});
});