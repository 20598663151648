define('fotobox/components/show-video/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			play() {
				var song = document.getElementById('player');
				song.play();
			},
			pause() {
				var song = document.getElementById('player');
				song.pause();
			}
		}
	});
});