define("fotobox/components/top-click/component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		button1: false,
		button2: false,
		toconfig: false,
		didReceiveAttrs() {
			this._super(...arguments);
		},

		actions: {
			button1() {
				this.set("button1", true);
				var _self = this;
				Ember.run.later(function () {
					_self.set("button2", false);
					_self.set("button1", false);
				}, 3000);
			},
			button2() {
				if (this.get("button1") == true) {
					if (this.get("toconfig")) {
						this.get("toconfig")();
					}
					this.set("button1", false);
					this.set("button2", false);
				} else {
					this.set("button1", false);
					this.set("button2", false);
				}
			}
		}
	});
});