define('fotobox/start/controller', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { socketip, pathtopics, pathtoaudios, pathtovideos } = _emberGetConfig.default;

    exports.default = Ember.Controller.extend({

        /*
            data: Ember.ArrayProxy.create ({content: Ember.A([
        
                {
                    name: "Marine-Ehrendenkmal",
                    image: "/assets/images/marine2.jpg",
                    type:"Bilder",
                    images: [{
                        img: "/assets/images/laboe1.jpg",
                        title: "Laboe 1"
                    }, {
                        img: "/assets/images/marine2.jpg",
                        title: "Laboe 2"
                    }],
                    text: {
                        title: "Marine Ehrendenkmal Laboe",
                        first: "",
                        secound: '<p>Der Erste Weltkrieg von 1914 bis 1918 hatte in der Kaiserlichen Marine viele Opfer gefordert, die auf See geblieben waren, für deren Angehörige es keinen Ort zum Trauern gab. 1925 schlug der ehemalige Obermaat Wilhelm Lammertz dem Deutschen Marinebund die Errichtung einer offiziellen Gedenkstätte für die im Ersten Weltkrieg gefallenen deutschen Marinesoldaten vor. Er beantragte beim Abgeordnetentag 1925 in Erfurt die Errichtung einer würdigen Gedenkstätte zu Ehren der 34.836 auf See gebliebenen Kameraden. Die Gemeinde Laboe befürwortete die Errichtung des Marine-Ehrenmals ein Jahr später. Die Baukosten betrugen rund 700.000 Reichsmark,[2] finanziert über Spenden und durch die Stadt Kiel.</p><p>1927 wurde ein Architektenwettbewerb ausgeschrieben, für den Heinz Stoffregens Entwurf (Titel: „Der Opferdank“) den ersten Preis gewann.[3] Allerdings wurde ihm der Preis nur formal zuerkannt, die Auswahlkommission bevorzugte den expressionistischen Entwurf des Architekten Gustav August Munzer, der jedoch die veranschlagten Baukosten von 500.000 Reichsmark bei weitem überschritten hätte. Die Preisrichter empfahlen deshalb, Munzers Entwurf in vereinfachter Form umzusetzen.[4]</p><p>Beim Design des Turms stellte sich Munzer entgegen anderen, verbreiteten Meinungen nicht etwa ein Schiffssegel, einen Bug oder einen U-Bootsturm vor, sondern „ein Bauwerk, mit der Erde und der See fest verwurzelt und gen Himmel steigend wie eine Flamme“.</p>'
                    }
                }
        
                ])}),*/
        active: false,
        pathtoaudios: '',
        pathtoaudiosno: '',
        in() {
            this.set("pathtoaudios", pathtoaudios);
            this.set("pathtoaudiosno", pathtoaudios + "nein");
        },
        actions: {
            transponder(id) {
                this.get("data").addObject({
                    name: "Top Secret",
                    image: pathtopics + "top-secret.jpg",
                    type: "Bilder",
                    images: [{
                        img: pathtopics + "top-secret.jpg",
                        title: "Laboe 1"
                    }, {
                        img: pathtopics + "1.png",
                        title: "Laboe 1"
                    }, {
                        img: pathtopics + "2.png",
                        title: "Laboe 1"
                    }],
                    text: {
                        title: "Lieber Besucher",
                        first: "Du hast eine geheime Information aktiviert.",
                        secound: '<p>Hier siehst du etwas ganz besonderes. Du hast diese Nachricht mit deinem Transponder aktiviert.</p>'
                    }
                });
                this.set("active", this.get("data.lastObject"));
            },
            click123() {
                console.log("test");
            },
            toconfig() {
                this.transitionToRoute('config');
            },
            up() {
                let found = false;
                let change = false;
                let _self = this;
                this.get("data").forEach(function (el, index) {
                    if (!change) {
                        if (found) {

                            _self.set("active", el);
                            change = true;
                        }
                        if (el == _self.get("active")) {
                            found = true;
                        }
                    }
                });
            },
            down() {
                let found = false;
                let change = false;
                let last = false;
                let _self = this;
                this.get("data").forEach(function (el, index) {
                    if (!change) {
                        if (found) {}
                        if (el == _self.get("active")) {
                            if (index - 1 >= 0) {
                                _self.set("active", _self.get("data").objectAt(index - 1));
                            }
                            change = true;
                            last = index;
                        }
                    }
                });
            }
        }
    });
});